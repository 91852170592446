import _map from "lodash-es/map";
import React, { useState } from 'react';
import { Tab, Tabs } from '@/common/components/tabs';
import { Text } from '@/common/components/text';
import { ArtworksSlider } from '../artwork-slider';
import { jsx as ___EmotionJSX } from "@emotion/core";

var CollectionSlider = function CollectionSlider(_ref) {
  var _currentCollection$ar, _currentCollection$ar2;

  var collections = _ref.collections;

  var _useState = useState(collections[0]),
      currentCollection = _useState[0],
      setCurrentCollection = _useState[1];

  var onCollectionSelect = function onCollectionSelect(collection) {
    setCurrentCollection(collection);
  };

  var artworks = (_currentCollection$ar = currentCollection === null || currentCollection === void 0 ? void 0 : (_currentCollection$ar2 = currentCollection.artworks) === null || _currentCollection$ar2 === void 0 ? void 0 : _currentCollection$ar2.nodes) !== null && _currentCollection$ar !== void 0 ? _currentCollection$ar : [];
  return ___EmotionJSX(React.Fragment, null, ___EmotionJSX(Tabs, null, _map(collections, function (collection) {
    return ___EmotionJSX(Tab, {
      key: collection.title,
      active: currentCollection === collection,
      onClick: function onClick() {
        return onCollectionSelect(collection);
      }
    }, ___EmotionJSX(Text, null, collection.title));
  })), ___EmotionJSX(ArtworksSlider, {
    items: artworks
  }));
};

export { CollectionSlider };