import React, { useCallback, useMemo, useState } from 'react';
import { Claim } from './claim';
import { jsx as ___EmotionJSX } from "@emotion/core";

var Claims = function Claims(_ref) {
  var claims = _ref.claims,
      rowDirection = _ref.rowDirection,
      style = _ref.style,
      className = _ref.className,
      showWriteEffect = _ref.showWriteEffect,
      isInlineText = _ref.isInlineText;

  var _useState = useState(0),
      currentClaimIndex = _useState[0],
      setCurrentClaimIndex = _useState[1];

  var _useState2 = useState(true),
      isVisible = _useState2[0],
      setIsVisible = _useState2[1];

  var _useState3 = useState(true),
      isFirstRender = _useState3[0],
      setIsFirstRender = _useState3[1];

  var handleRestartTyping = useCallback(function () {
    setIsVisible(false);
    setIsVisible(true);
  }, []);
  var handleTypeNextClaim = useCallback(function () {
    var nextClaimIndex = currentClaimIndex + 2 <= ((claims === null || claims === void 0 ? void 0 : claims.length) || 0) ? currentClaimIndex + 1 : 0;

    if (nextClaimIndex === currentClaimIndex) {
      handleRestartTyping();
      return;
    }

    setCurrentClaimIndex(nextClaimIndex);
  }, [claims, currentClaimIndex, handleRestartTyping]);
  var onBeforeBackspace = useCallback(function () {
    if (isFirstRender) {
      setIsFirstRender(false);
    }
  }, [isFirstRender, setIsFirstRender]);
  var claimElements = useMemo(function () {
    var claim = claims === null || claims === void 0 ? void 0 : claims[currentClaimIndex];

    if (!claim) {
      return null;
    }

    return ___EmotionJSX(Claim, {
      isFirstRender: isFirstRender,
      claim: claim,
      handleTypingDone: handleTypeNextClaim,
      onBeforeBackspace: onBeforeBackspace,
      backspaceWhenDone: true,
      style: style,
      className: className,
      key: currentClaimIndex,
      rowDirection: rowDirection,
      showWriteEffect: showWriteEffect,
      isInlineText: isInlineText
    });
  }, [claims, currentClaimIndex, isFirstRender, handleTypeNextClaim, onBeforeBackspace, style, className, rowDirection, showWriteEffect, isInlineText]);
  return ___EmotionJSX(React.Fragment, null, isVisible && claimElements);
};

export { Claims };