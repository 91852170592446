import { useStoreActions, useStoreState } from '@/common/providers/easy-peasy/hooks';

function usePostsStore() {
  var storedSearchQuery = useStoreState(function (state) {
    return state.posts.storedSearchQuery;
  });
  var storedCurrentPage = useStoreState(function (state) {
    return state.posts.storedCurrentPage;
  });
  var setStoredSearchQuery = useStoreActions(function (actions) {
    return actions.posts.setStoredSearchQuery;
  });
  var setStoredCurrentPage = useStoreActions(function (actions) {
    return actions.posts.setStoredCurrentPage;
  });
  return {
    storedSearchQuery: storedSearchQuery,
    storedCurrentPage: storedCurrentPage,
    setStoredSearchQuery: setStoredSearchQuery,
    setStoredCurrentPage: setStoredCurrentPage
  };
}

export { usePostsStore };