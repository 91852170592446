import { useStaticQuery } from 'gatsby';
import React from 'react';
import { BlockTitle } from '@/common/components/text';
import { PostList } from '@/post/componenets/post-list/post-list';
import { jsx as ___EmotionJSX } from "@emotion/core";
var query = "2427496115";

var FeaturedPostList = function FeaturedPostList(_ref) {
  var _queryData$cms, _queryData$cms$posts;

  var data = _ref.data;
  var queryData = useStaticQuery(query);
  var posts = queryData === null || queryData === void 0 ? void 0 : (_queryData$cms = queryData.cms) === null || _queryData$cms === void 0 ? void 0 : (_queryData$cms$posts = _queryData$cms.posts) === null || _queryData$cms$posts === void 0 ? void 0 : _queryData$cms$posts.data;
  var title = data === null || data === void 0 ? void 0 : data.title;
  return ___EmotionJSX(React.Fragment, null, title && ___EmotionJSX(BlockTitle, null, title), ___EmotionJSX(PostList, {
    posts: posts
  }));
}; // default export needed for code splitting
// eslint-disable-next-line import/no-default-export


export { FeaturedPostList, FeaturedPostList as default };