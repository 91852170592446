import { useStaticQuery } from 'gatsby';
import React from 'react';
import { ArtistList } from '@/artist/components/artist-list';
import { BlockTitle } from '@/common/components/text';
import { jsx as ___EmotionJSX } from "@emotion/core";
var query = "3284086390";

var FeaturedArtistList = function FeaturedArtistList(_ref) {
  var data = _ref.data;
  var queryData = useStaticQuery(query);
  var artists = queryData.moonaapi.artistProfiles.nodes;
  var title = data === null || data === void 0 ? void 0 : data.title;
  return ___EmotionJSX(React.Fragment, null, title && ___EmotionJSX(BlockTitle, null, title), ___EmotionJSX(ArtistList, {
    limit: data === null || data === void 0 ? void 0 : data.limit,
    artists: artists
  }));
}; // default export needed for code splitting
// eslint-disable-next-line import/no-default-export


export { FeaturedArtistList, FeaturedArtistList as default };