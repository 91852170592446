import { useStoreActions, useStoreState } from '@/common/providers/easy-peasy/hooks';

function useCollectionCarousel() {
  var storedCarouselActiveSlide = useStoreState(function (state) {
    return state.collectionCarousel.storedCarouselActiveSlide;
  });
  var setCarouselActiveSlide = useStoreActions(function (actions) {
    return actions.collectionCarousel.setCarouselActiveSlide;
  });
  return {
    storedCarouselActiveSlide: storedCarouselActiveSlide,
    setCarouselActiveSlide: setCarouselActiveSlide
  };
}

export { useCollectionCarousel };