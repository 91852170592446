import _merge from "lodash-es/merge";
import { useMutation } from '@apollo/react-hooks';
import { useCallback } from 'react';
import { useAuth } from '@/common/hooks';

function useAuthenticatedMutation(mutation, options) {
  var _useAuth = useAuth(),
      accessToken = _useAuth.accessToken;

  var _useMutation = useMutation(mutation, options),
      request = _useMutation[0],
      result = _useMutation[1];

  var authenticatedRequest = useCallback(function (params) {
    return request(_merge(params, {
      context: {
        headers: {
          Authorization: "Bearer " + accessToken
        }
      }
    }));
  }, [accessToken, request]);
  return [authenticatedRequest, result];
}

export { useAuthenticatedMutation };