import { useStaticQuery } from 'gatsby';
import React from 'react';
import { BlockTitle } from '@/common/components/text';
import { ExhibitionGrid } from '@/exhibition/components/exhibition-grid';
import { jsx as ___EmotionJSX } from "@emotion/core";
var query = "759997380";

var FeaturedExhibitionGrid = function FeaturedExhibitionGrid(_ref) {
  var data = _ref.data;
  var queryData = useStaticQuery(query);
  var exhibitions = queryData.moonaapi.exhibitions.nodes;
  var title = data === null || data === void 0 ? void 0 : data.title;
  return ___EmotionJSX(React.Fragment, null, title && ___EmotionJSX(BlockTitle, null, title), ___EmotionJSX(ExhibitionGrid, {
    limit: data === null || data === void 0 ? void 0 : data.limit,
    exhibitions: exhibitions
  }));
}; // default export needed for code splitting
// eslint-disable-next-line import/no-default-export


export { FeaturedExhibitionGrid, FeaturedExhibitionGrid as default };