import _toUpper from "lodash-es/toUpper";
import { ContentBlockType } from '../cms-types/content-block-types';

function isArtistsListBlock(contentBlockItem) {
  return _toUpper(contentBlockItem.type) === ContentBlockType.Artists;
}

function isArtworkGridBlock(contentBlockItem) {
  return _toUpper(contentBlockItem.type) === ContentBlockType.Artworks;
}

function isPostsGridBlock(contentBlockItem) {
  return _toUpper(contentBlockItem.type) === ContentBlockType.Posts;
}

function isCallToActionBlock(contentBlockItem) {
  return _toUpper(contentBlockItem.type) === ContentBlockType.CallToAction;
}

function isClaimTeaserBlock(contentBlockItem) {
  return _toUpper(contentBlockItem.type) === ContentBlockType.ClaimTeaser;
}

function isCollectionTeaserBlock(contentBlockItem) {
  return _toUpper(contentBlockItem.type) === ContentBlockType.CollectionsTeaser;
}

function isCollectionTeaserCarouselBlock(contentBlockItem) {
  return _toUpper(contentBlockItem.type) === ContentBlockType.CollectionsTeaserCarousel;
}

function isContentSectionBlock(contentBlockItem) {
  return _toUpper(contentBlockItem.type) === ContentBlockType.ContentSection;
}

function isCuratorsListBlock(contentBlockItem) {
  return _toUpper(contentBlockItem.type) === ContentBlockType.Curators;
}

function isCuratorsPickTeaserBlock(contentBlockItem) {
  return _toUpper(contentBlockItem.type) === ContentBlockType.CuratorsPickTeaser;
}

function isNumbersTeaserBlock(contentBlockItem) {
  return _toUpper(contentBlockItem.type) === ContentBlockType.NumberTeaser;
}

function isNavigationContentSectionBlock(contentBlockItem) {
  return _toUpper(contentBlockItem.type) === ContentBlockType.NavigationContentSection;
}

function isExhibitionsGridBlock(contentBlockItem) {
  return _toUpper(contentBlockItem.type) === ContentBlockType.Exhibitions;
}

function isNewsletterSubscriptionBlock(contentBlockItem) {
  return _toUpper(contentBlockItem.type) === ContentBlockType.NewsletterSubscription;
}

function isZigZagBlock(contentBlockItem) {
  return _toUpper(contentBlockItem.type) === ContentBlockType.ZigZag;
}

function isFeaturedArtworksBlock(contentBlockItem) {
  return _toUpper(contentBlockItem.type) === ContentBlockType.FeaturedArtworks;
}

export { isContentSectionBlock, isArtworkGridBlock, isPostsGridBlock, isCollectionTeaserBlock, isCollectionTeaserCarouselBlock, isArtistsListBlock, isCallToActionBlock, isClaimTeaserBlock, isCuratorsListBlock, isCuratorsPickTeaserBlock, isNumbersTeaserBlock, isNavigationContentSectionBlock, isExhibitionsGridBlock, isNewsletterSubscriptionBlock, isZigZagBlock, isFeaturedArtworksBlock };