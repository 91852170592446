import _taggedTemplateLiteralLoose from "@babel/runtime/helpers/esm/taggedTemplateLiteralLoose";
import _values from "lodash-es/values";
import _merge from "lodash-es/merge";
import _keyBy from "lodash-es/keyBy";

var _templateObject;

import { useQuery } from '@apollo/react-hooks';
import { useStaticQuery } from 'gatsby';
import gql from 'graphql-tag';
import React, { useMemo } from 'react';
import { BlockTitle } from '@/common/components/text';
import { ExhibitionFeaturedSlider } from './exhibition-featured-slider';
import { jsx as ___EmotionJSX } from "@emotion/core";
var FeaturedExhibitionsUpdate = gql(_templateObject || (_templateObject = _taggedTemplateLiteralLoose(["\n  query FeaturedExhibitionsUpdate {\n    featuredExhibitions {\n      artworks {\n        nodes {\n          id\n          editionAvailableCount\n          editionSalesCount\n          editionPendingCount\n          editionSize\n          purchasePrice {\n            amount\n            formattedValue\n          }\n          isBuyable\n        }\n      }\n      slug\n    }\n  }\n"])));
var query = "4174287667";

var ExhibitionFeaturedCollection = function ExhibitionFeaturedCollection(_ref) {
  var title = _ref.title;

  var _useQuery = useQuery(FeaturedExhibitionsUpdate, {
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true
  }),
      featuredExhibitionsUpdated = _useQuery.data;

  var queryData = useStaticQuery(query); // eslint-disable-next-line no-console

  console.log('queryData', queryData);
  var artworks = useMemo(function () {
    if (!featuredExhibitionsUpdated) {
      return queryData.moonaapi.featuredExhibitions;
    } else {
      var mergedArtworks = _merge(_keyBy(queryData.moonaapi.featuredExhibitions, 'slug'), _keyBy(featuredExhibitionsUpdated.featuredExhibitions, 'slug'));

      return _values(mergedArtworks);
    }
  }, [featuredExhibitionsUpdated, queryData]); // eslint-disable-next-line no-console

  console.log('artworks', artworks);
  return ___EmotionJSX(React.Fragment, null, title && ___EmotionJSX(BlockTitle, null, title), ___EmotionJSX(ExhibitionFeaturedSlider, {
    collections: artworks
  }));
};

export { ExhibitionFeaturedCollection };