import { useStoreActions, useStoreState } from '@/common/providers/easy-peasy/hooks';

function useArtistsStore() {
  var storedSearchQuery = useStoreState(function (state) {
    return state.artists.storedSearchQuery;
  });
  var storedSortQuery = useStoreState(function (state) {
    return state.artistsSort.storedSortQuery;
  });
  var storedCurrentPage = useStoreState(function (state) {
    return state.artists.storedCurrentPage;
  });
  var setStoredSearchQuery = useStoreActions(function (actions) {
    return actions.artists.setStoredSearchQuery;
  });
  var setStoredSortQuery = useStoreActions(function (actions) {
    return actions.artistsSort.setStoredSortQuery;
  });
  var setStoredCurrentPage = useStoreActions(function (actions) {
    return actions.artists.setStoredCurrentPage;
  });
  return {
    storedSearchQuery: storedSearchQuery,
    storedSortQuery: storedSortQuery,
    storedCurrentPage: storedCurrentPage,
    setStoredSearchQuery: setStoredSearchQuery,
    setStoredSortQuery: setStoredSortQuery,
    setStoredCurrentPage: setStoredCurrentPage
  };
}

export { useArtistsStore };