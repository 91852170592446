import _isError from "lodash-es/isError";
import React, { useCallback, useMemo, useRef } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { jsx as ___EmotionJSX } from "@emotion/core";
var RECAPTCHA_SITE_KEY = CONFIG.recaptcha.key;

function useCaptcha() {
  var captchaRef = useRef(null);
  var executionRef = useRef({
    promise: null,
    resolve: null,
    reject: null
  });
  var resetCaptcha = useCallback(function () {
    var _captchaRef$current;

    (_captchaRef$current = captchaRef.current) === null || _captchaRef$current === void 0 ? void 0 : _captchaRef$current.reset();
    executionRef.current.promise = null;
    executionRef.current.resolve = null;
    executionRef.current.reject = null;
  }, []);
  var handleResponse = useCallback(function (response) {
    if (executionRef.current.promise) {
      if (_isError(response)) {
        var _executionRef$current, _executionRef$current2;

        (_executionRef$current = (_executionRef$current2 = executionRef.current).reject) === null || _executionRef$current === void 0 ? void 0 : _executionRef$current.call(_executionRef$current2, new Error('An unexpected error occurred while trying to process the captcha. Please refresh the page and try again or contact support if the error persists.'));
      } else if (!response) {
        var _executionRef$current3, _executionRef$current4;

        (_executionRef$current3 = (_executionRef$current4 = executionRef.current).reject) === null || _executionRef$current3 === void 0 ? void 0 : _executionRef$current3.call(_executionRef$current4, new Error('The captcha has expired. Please refresh the page and try again or contact support if the error persists.'));
      } else {
        var _executionRef$current5, _executionRef$current6;

        (_executionRef$current5 = (_executionRef$current6 = executionRef.current).resolve) === null || _executionRef$current5 === void 0 ? void 0 : _executionRef$current5.call(_executionRef$current6, response);
      }

      resetCaptcha();
    }
  }, [resetCaptcha]);
  var captchaElement = useMemo(function () {
    return ___EmotionJSX(ReCAPTCHA, {
      ref: captchaRef,
      size: "invisible",
      badge: "inline",
      sitekey: RECAPTCHA_SITE_KEY,
      onErrored: function onErrored() {
        return handleResponse(new Error('captcha threw an error'));
      },
      onChange: handleResponse
    });
  }, [handleResponse]);
  var executeCaptcha = useCallback(function () {
    var _captchaRef$current2;

    if (executionRef.current.promise) {
      return executionRef.current.promise;
    }

    executionRef.current.promise = new Promise(function (resolve, reject) {
      executionRef.current.resolve = resolve;
      executionRef.current.reject = reject;
    });
    (_captchaRef$current2 = captchaRef.current) === null || _captchaRef$current2 === void 0 ? void 0 : _captchaRef$current2.execute();
    return executionRef.current.promise;
  }, []);
  return {
    captchaElement: captchaElement,
    executeCaptcha: executeCaptcha
  };
}

export { useCaptcha };