import _styled from "@emotion/styled-base";

// todo check standardized font sizes
var TitleLight = _styled("h3", {
  target: "edyajzk0",
  label: "title-light_TitleLight"
})(function (props) {
  return props.theme.fonts.heading.h3(props);
}, ";font-weight:400;" + (process.env.NODE_ENV === "production" ? "" : "/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbIi9idWlsZHMvbW9vbmEvbW9vbmEtZnJvbnRlbmQvc3JjL2NvbW1vbi9jb21wb25lbnRzL3RleHQvdGl0bGUtbGlnaHQudHN4Il0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUc0QiIsImZpbGUiOiIvYnVpbGRzL21vb25hL21vb25hLWZyb250ZW5kL3NyYy9jb21tb24vY29tcG9uZW50cy90ZXh0L3RpdGxlLWxpZ2h0LnRzeCIsInNvdXJjZXNDb250ZW50IjpbImltcG9ydCBzdHlsZWQgZnJvbSAnQGVtb3Rpb24vc3R5bGVkJztcblxuLy8gdG9kbyBjaGVjayBzdGFuZGFyZGl6ZWQgZm9udCBzaXplc1xuY29uc3QgVGl0bGVMaWdodCA9IHN0eWxlZC5oM2BcbiAgJHsocHJvcHMpID0+IHByb3BzLnRoZW1lLmZvbnRzLmhlYWRpbmcuaDMocHJvcHMpfTtcbiAgZm9udC13ZWlnaHQ6IDQwMDtcbmA7XG5cbmV4cG9ydCB7IFRpdGxlTGlnaHQgfTtcbiJdfQ== */"));

export { TitleLight };