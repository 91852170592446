import _sample from "lodash-es/sample";
import { Color } from '@/common/utils/cms-types/colors';

var claimTextColor = function claimTextColor(color) {
  return function (_ref) {
    var theme = _ref.theme;

    switch (color) {
      case Color.Default:
        return theme.colors.themeColors.default;

      case Color.Red:
        return theme.colors.themeColors.red;

      case Color.Green:
        return theme.colors.themeColors.green;

      case Color.Blue:
        return theme.colors.themeColors.blue;
    }

    return theme.colors.themeColors.default;
  };
};

var randomColor = function randomColor(_ref2) {
  var theme = _ref2.theme;
  return _sample([theme.colors.themeColors.red, theme.colors.themeColors.green, theme.colors.themeColors.blue]);
};

export { claimTextColor, randomColor };