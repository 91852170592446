import parseISO from "date-fns/parseISO";
import format from "date-fns/format";

var formatPostDate = function formatPostDate(date) {
  if (!date) {
    return '';
  }

  return format(parseISO(date), 'd. MMMM yyyy');
};

export { formatPostDate };