var ContentElementType;

(function (ContentElementType) {
  ContentElementType["CallToAction"] = "CALLTOACTION";
  ContentElementType["ContentElement"] = "CONTENTELEMENT";
  ContentElementType["Headline"] = "HEADLINE";
  ContentElementType["SeparatorElement"] = "SEPARATORELEMENT";
  ContentElementType["Video"] = "VIDEO";
  ContentElementType["Image"] = "IMAGEELEMENT";
  ContentElementType["InfoTeasers"] = "INFOTEASERS";
})(ContentElementType || (ContentElementType = {}));

export { ContentElementType };