import { useStaticQuery } from 'gatsby';
import React from 'react';
import { BlockTitle } from '@/common/components/text';
import { CollectionSlider } from './collection-slider';
/* const FeaturedSpotlightsUpdate = gql`
  query FeaturedSpotlightsUpdate {
    featuredSpotlights {
      artworks {
        nodes {
          id
          editionAvailableCount
          editionSalesCount
          editionPendingCount
          editionSize
          purchasePrice {
            amount
            formattedValue
          }
          isBuyable
        }
      }
    }
  }
`; */

import { jsx as ___EmotionJSX } from "@emotion/core";
var query = "1332858705";

var FeaturedCollectionSlider = function FeaturedCollectionSlider(_ref) {
  var title = _ref.title;

  /* const { data: featuredSpotlightsUpdated } = useQuery<
    FeaturedSpotlightsUpdateQueryVariables,
    FeaturedSpotlightsUpdateQuery
  >(FeaturedSpotlightsUpdate, {
    ssr: true,
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,
  }); */
  var queryData = useStaticQuery(query);
  /* const artworks = useMemo(() => {
    if (!featuredSpotlightsUpdated) {
      return queryData.moonaapi.artworks.nodes;
    } else {
      const mergedArtworks = merge(
        keyBy(queryData.moonaapi.artworks.nodes, 'id'),
        keyBy(featuredSpotlightsUpdated.artworks.nodes, 'id'),
      );
      return values(mergedArtworks);
    }
  }, [featuredSpotlightsUpdated, queryData.moonaapi.artworks.nodes]); */
  // eslint-disable-next-line no-console

  console.log('exhibitions spotlights');
  return ___EmotionJSX(React.Fragment, null, title && ___EmotionJSX(BlockTitle, null, title), ___EmotionJSX(CollectionSlider, {
    collections: queryData.moonaapi.featuredSpotlights
  }));
};

export { FeaturedCollectionSlider };