import React from 'react';
import { ContentBlocks } from './content-blocks';
import { jsx as ___EmotionJSX } from "@emotion/core";

var Content = function Content(_ref) {
  var contentBlocks = _ref.contentBlocks,
      style = _ref.style,
      className = _ref.className;
  return ___EmotionJSX("div", {
    style: style,
    className: className
  }, ___EmotionJSX(ContentBlocks, {
    contentBlocks: contentBlocks
  }));
};

Content.displayName = "Content";
export { Content };