import _styled from "@emotion/styled-base";
import React from 'react';
import { jsx as ___EmotionJSX } from "@emotion/core";

var Base = _styled("div", {
  target: "e1au35bq0",
  label: "separator_Base"
})("border-bottom:", function (_ref) {
  var theme = _ref.theme;
  return "1px solid " + theme.colors.border.default;
}, ";height:0px;margin:1rem 0 0 0;width:100%;" + (process.env.NODE_ENV === "production" ? "" : "/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbIi9idWlsZHMvbW9vbmEvbW9vbmEtZnJvbnRlbmQvc3JjL2NvbW1vbi9jb21wb25lbnRzL2NvbnRlbnQvY29udGVudC1lbGVtZW50L3NlcGFyYXRvci50c3giXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBR3VCIiwiZmlsZSI6Ii9idWlsZHMvbW9vbmEvbW9vbmEtZnJvbnRlbmQvc3JjL2NvbW1vbi9jb21wb25lbnRzL2NvbnRlbnQvY29udGVudC1lbGVtZW50L3NlcGFyYXRvci50c3giLCJzb3VyY2VzQ29udGVudCI6WyJpbXBvcnQgc3R5bGVkIGZyb20gJ0BlbW90aW9uL3N0eWxlZCc7XG5pbXBvcnQgUmVhY3QsIHsgQ1NTUHJvcGVydGllcyB9IGZyb20gJ3JlYWN0JztcblxuY29uc3QgQmFzZSA9IHN0eWxlZC5kaXZgXG4gIGJvcmRlci1ib3R0b206ICR7KHsgdGhlbWUgfSkgPT4gYDFweCBzb2xpZCAke3RoZW1lLmNvbG9ycy5ib3JkZXIuZGVmYXVsdH1gfTtcbiAgaGVpZ2h0OiAwcHg7XG4gIG1hcmdpbjogMXJlbSAwIDAgMDtcbiAgd2lkdGg6IDEwMCU7XG5gO1xuXG5leHBvcnQgaW50ZXJmYWNlIEhlYWRsaW5lUHJvcHMge1xuICBlbmFibGVkOiBib29sZWFuIHwgbnVsbCB8IHVuZGVmaW5lZDtcbiAgc3R5bGU/OiBDU1NQcm9wZXJ0aWVzO1xuICBjbGFzc05hbWU/OiBzdHJpbmc7XG59XG5cbmNvbnN0IFNlcGFyYXRvcjogUmVhY3QuRkM8SGVhZGxpbmVQcm9wcz4gPSAoe1xuICBlbmFibGVkLFxuICBzdHlsZSxcbiAgY2xhc3NOYW1lLFxuICBjaGlsZHJlbixcbn0pID0+IHtcbiAgaWYgKCFlbmFibGVkKSB7XG4gICAgcmV0dXJuIG51bGw7XG4gIH1cbiAgcmV0dXJuIChcbiAgICA8QmFzZSBzdHlsZT17c3R5bGV9IGNsYXNzTmFtZT17Y2xhc3NOYW1lfT5cbiAgICAgIHtjaGlsZHJlbn1cbiAgICA8L0Jhc2U+XG4gICk7XG59O1xuXG5leHBvcnQgeyBTZXBhcmF0b3IgfTtcbiJdfQ== */"));

var Separator = function Separator(_ref2) {
  var enabled = _ref2.enabled,
      style = _ref2.style,
      className = _ref2.className,
      children = _ref2.children;

  if (!enabled) {
    return null;
  }

  return ___EmotionJSX(Base, {
    style: style,
    className: className
  }, children);
};

Separator.displayName = "Separator";
export { Separator };