import _toUpper from "lodash-es/toUpper";
import { ContentElementType } from '../cms-types/content-element-types';

function isCallToActionElement(contentElement) {
  return _toUpper(contentElement.type) === ContentElementType.CallToAction;
}

function isContentElement(contentElement) {
  return _toUpper(contentElement.type) === ContentElementType.ContentElement;
}

function isHeadlineElement(contentElement) {
  return _toUpper(contentElement.type) === ContentElementType.Headline;
}

function isSeparatorElement(contentElement) {
  return _toUpper(contentElement.type) === ContentElementType.SeparatorElement;
}

function isVideoElement(contentElement) {
  return _toUpper(contentElement.type) === ContentElementType.Video;
}

function isImageElement(contentElement) {
  return _toUpper(contentElement.type) === ContentElementType.Image;
}

function isInfoTeasersElement(contentElement) {
  return _toUpper(contentElement.type) === ContentElementType.InfoTeasers;
}

export { isCallToActionElement, isContentElement, isHeadlineElement, isVideoElement, isImageElement, isInfoTeasersElement, isSeparatorElement };