import React from 'react';
import { ContentBlocks } from '@/common/components/content';
import { mapMaybe } from '@/common/utils/map-maybe';
import { jsx as ___EmotionJSX } from "@emotion/core";

var ContentSection = function ContentSection(_ref) {
  var section = _ref.section;
  var contentBlocks = section.content;
  return ___EmotionJSX(ContentBlocks, {
    contentBlocks: mapMaybe(contentBlocks)
  });
};

ContentSection.displayName = "ContentSection";
export { ContentSection };