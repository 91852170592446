import _styled from "@emotion/styled-base";

function _EMOTION_STRINGIFIED_CSS_ERROR__() { return "You have tried to stringify object returned from `css` function. It isn't supposed to be used directly (e.g. as value of the `className` prop), but rather handed to emotion so it can handle it (e.g. as value of `css` prop)."; }

// todo check standardized font sizes
var Subtitle = _styled("h4", {
  target: "e15r9xpe0",
  label: "subtitle_Subtitle"
})(process.env.NODE_ENV === "production" ? {
  name: "13lih27",
  styles: "font-size:1rem;margin-bottom:0.72rem;"
} : {
  name: "13lih27",
  styles: "font-size:1rem;margin-bottom:0.72rem;",
  map: "/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbIi9idWlsZHMvbW9vbmEvbW9vbmEtZnJvbnRlbmQvc3JjL2NvbW1vbi9jb21wb25lbnRzL3RleHQvc3VidGl0bGUudHN4Il0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUcwQiIsImZpbGUiOiIvYnVpbGRzL21vb25hL21vb25hLWZyb250ZW5kL3NyYy9jb21tb24vY29tcG9uZW50cy90ZXh0L3N1YnRpdGxlLnRzeCIsInNvdXJjZXNDb250ZW50IjpbImltcG9ydCBzdHlsZWQgZnJvbSAnQGVtb3Rpb24vc3R5bGVkJztcblxuLy8gdG9kbyBjaGVjayBzdGFuZGFyZGl6ZWQgZm9udCBzaXplc1xuY29uc3QgU3VidGl0bGUgPSBzdHlsZWQuaDRgXG4gIGZvbnQtc2l6ZTogMXJlbTtcbiAgbWFyZ2luLWJvdHRvbTogMC43MnJlbTtcbmA7XG5cbmV4cG9ydCB7IFN1YnRpdGxlIH07XG4iXX0= */",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
});

export { Subtitle };