var ContentBlockType;

(function (ContentBlockType) {
  ContentBlockType["Artists"] = "ARTISTS";
  ContentBlockType["Artworks"] = "ARTWORKS";
  ContentBlockType["Posts"] = "POSTS";
  ContentBlockType["CallToAction"] = "CALLTOACTION";
  ContentBlockType["ClaimTeaser"] = "CLAIMTEASER";
  ContentBlockType["CollectionsTeaser"] = "COLLECTIONSTEASER";
  ContentBlockType["CollectionsTeaserCarousel"] = "COLLECTIONSTEASERCAROUSEL";
  ContentBlockType["ContentSection"] = "CONTENTSECTION";
  ContentBlockType["Curators"] = "CURATORS";
  ContentBlockType["CuratorsPickTeaser"] = "CURATORSPICKTEASER";
  ContentBlockType["NumberTeaser"] = "NUMBERTEASER";
  ContentBlockType["NavigationContentSection"] = "NAVIGATIONCONTENTSECTION";
  ContentBlockType["Exhibitions"] = "EXHIBITIONS";
  ContentBlockType["NewsletterSubscription"] = "NEWSLETTERSUBSCRIPTION";
  ContentBlockType["ZigZag"] = "ZIGZAG";
  ContentBlockType["FeaturedArtworks"] = "FEATUREDARTWORKS";
})(ContentBlockType || (ContentBlockType = {}));

export { ContentBlockType };